import React, { useState, useEffect } from "react";
import "../style/App.css";
import api from "../modules/Api.js";
import DiscoveryChart from "../modules/discoveryChart.js"; // Import the modularized chart

export default function Discovery() {
  const [fetchedMetrics, setFetchedMetrics] = useState([]);
  const [fetchedMedia, setFetchedMedia] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Track loading state
  const [accountUrl, setAccountUrl] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const fetchMetrics = async () => {
    if (!accountUrl || !startDate || !endDate) {
      alert("Please select an account and date range");
      return;
    }
    try {
      setIsLoading(true); // Start loading
      const response = await api.post("aggregations/discovery/graph", {
        account: accountUrl,
        startDate: startDate,
        endDate: endDate,
      });
      if (response.status !== 200) throw new Error("Failed to fetch metrics");

      const metrics = response.data.metrics || [];
      const media = response.data.media || [];

      setFetchedMetrics(metrics);
      setFetchedMedia(media);
    } catch (error) {
      console.error("Error fetching metrics:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const Spinner = () => (
    <div className="fetch-spinner">{/* Simple spinner using CSS */}</div>
  );

  return (
    <div className="dashboard">
      <div className="fetch__data__form">
        <select
          value={accountUrl}
          onChange={(e) => setAccountUrl(e.target.value)}
        >
          <option value="" disabled>
            Account
          </option>
          <option value="https://dometic.com/">Dometic</option>
          <option value="https://stellarequipment.com">
            Stellar Equipment
          </option>
          <option value="https://www.poc.com">POC</option>
          <option value="https://www.jlindeberg.com">J.Lindeberg</option>
          <option value="https://bmk.health">BKM Health</option>
        </select>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <button onClick={fetchMetrics} className="button-with-spinner">
          {isLoading ? <Spinner /> : "FETCH ⚡️"}
        </button>
      </div>

      {/* Render the chart dynamically when data is ready */}
      {fetchedMetrics.length > 0 && fetchedMedia.length > 0 && (
        <DiscoveryChart metrics={fetchedMetrics} media={fetchedMedia} />
      )}
    </div>
  );
}
