import React from "react";
import {
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryTooltip,
  VictoryScatter,
  VictoryTheme,
} from "victory";
import moment from "moment";
import "../style/App.css";

const CustomMediaFlyout = ({ x, y, datum }) => {
  const isVideo = datum.media_type === "VIDEO";

  const mediaWidth = 55; // Fixed width
  const aspectRatio = 16 / 9; // Adjust based on the media format
  const mediaHeight = mediaWidth * aspectRatio; // Dynamically calculate height
  const offset = 10; // Offset above the dot

  return (
    <g
      transform={`translate(${x - mediaWidth / 2}, ${
        y - mediaHeight - offset
      })`}
    >
      <foreignObject width={mediaWidth} height={mediaHeight}>
        {isVideo ? (
          <video
            src={datum.media_url}
            autoPlay
            loop
            muted
            playsInline
            style={{
              display: "block",
              width: "100%",
              height: "100%",
              objectFit: "contain",
              borderRadius: "5px",
            }}
          />
        ) : (
          <img
            src={datum.media_url}
            alt="Media"
            style={{
              display: "block",
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        )}
      </foreignObject>
    </g>
  );
};

const ContentChart = ({ graphData, mediaData, logScale }) => {
  const { metrics, visitors } = graphData;

  // Define unique colors for each metric
  const colorMapping = {
    ig_reels_video_view_total_time: "#FF4E4F",
    ig_reels_avg_watch_time: "#FFA726",
    ig_reels_aggregated_all_plays_count: "#4E79FF",
    organic_instagram_visitors: "#96F0D9",
    direct_visitors: "#A78BFA",
    organic_google_visitors: "#FFD700",
  };

  // Preprocess data conditionally for log scale
  const preprocessDataForLogScale = (data) =>
    data.map((d) => ({
      x: new Date(d.x),
      y: logScale && d.y <= 0 ? 1 : d.y,
    }));

  // Flatten visitors and metrics into a single data structure
  const combinedMetrics = [
    ...metrics.map((metric) => ({
      name: metric.name,
      data: preprocessDataForLogScale(metric.values),
      color: colorMapping[metric.name] || "#FFFFFF",
    })),
    {
      name: "organic_instagram_visitors",
      data: preprocessDataForLogScale(
        visitors.map((v) => ({
          x: new Date(v.hour),
          y: v.organic_instagram_visitors || 0,
        }))
      ),
      color: colorMapping["organic_instagram_visitors"],
    },
    {
      name: "direct_visitors",
      data: preprocessDataForLogScale(
        visitors.map((v) => ({
          x: new Date(v.hour),
          y: v.direct_visitors || 0,
        }))
      ),
      color: colorMapping["direct_visitors"],
    },
    {
      name: "organic_google_visitors",
      data: preprocessDataForLogScale(
        visitors.map((v) => ({
          x: new Date(v.hour),
          y: v.organic_google_visitors || 0,
        }))
      ),
      color: colorMapping["organic_google_visitors"],
    },
  ];

  // Separate visitors and metrics for different axes
  const visitorMetrics = combinedMetrics.filter((metric) =>
    [
      "organic_instagram_visitors",
      "direct_visitors",
      "organic_google_visitors",
    ].includes(metric.name)
  );

  const performanceMetrics = combinedMetrics.filter(
    (metric) =>
      ![
        "organic_instagram_visitors",
        "direct_visitors",
        "organic_google_visitors",
      ].includes(metric.name)
  );

  // Calculate ranges
  const visitorsRange = [
    Math.min(...visitorMetrics.flatMap((m) => m.data.map((d) => d.y))),
    Math.max(...visitorMetrics.flatMap((m) => m.data.map((d) => d.y))),
  ];

  const metricsRange = [
    Math.min(...performanceMetrics.flatMap((m) => m.data.map((d) => d.y))),
    Math.max(...performanceMetrics.flatMap((m) => m.data.map((d) => d.y))),
  ];

  // Set shared Y domain
  const sharedYDomain = [0, 10];

  // Normalize function
  const normalize = (value, range) =>
    ((value - range[0]) / (range[1] - range[0] || 1)) * // Avoid division by zero
      (sharedYDomain[1] - sharedYDomain[0]) +
    sharedYDomain[0];

  // Normalize data
  const normalizedVisitorMetrics = visitorMetrics.map((metric) => ({
    ...metric,
    data: metric.data.map((d) => ({
      x: d.x,
      y: normalize(d.y, visitorsRange),
    })),
  }));

  const normalizedPerformanceMetrics = performanceMetrics.map((metric) => ({
    ...metric,
    data: metric.data.map((d) => ({
      x: d.x,
      y: normalize(d.y, metricsRange),
    })),
  }));

  // Tick format functions
  const visitorTickFormat = (t) =>
    Math.round(
      ((t - sharedYDomain[0]) / (sharedYDomain[1] - sharedYDomain[0])) *
        (visitorsRange[1] - visitorsRange[0]) +
        visitorsRange[0]
    );

  const metricsTickFormat = (t) =>
    Math.round(
      ((t - sharedYDomain[0]) / (sharedYDomain[1] - sharedYDomain[0])) *
        (metricsRange[1] - metricsRange[0]) +
        metricsRange[0]
    );

  // Generate tick values for Y-axes
  const yTickValues = Array.from({ length: sharedYDomain[1] + 1 }, (_, i) => i);

  return (
    <div>
      <VictoryChart
        theme={VictoryTheme.material}
        domain={{ y: sharedYDomain }}
        domainPadding={20}
        scale={{ x: "time", y: "linear" }} // Use linear scale after normalization
        width={800}
        height={400}
        style={{
          parent: {
            background: "#141414",
          },
        }}
      >
        {/* X-Axis */}
        <VictoryAxis
          tickFormat={(t) => moment(t).format("D")}
          style={{
            tickLabels: { fontSize: 10, fill: "#e3e3e3" },
            axis: { fill: "none", stroke: "none" },
          }}
        />

        {/* Left Y-Axis for Visitors */}
        <VictoryAxis
          dependentAxis
          orientation="left"
          tickValues={yTickValues}
          tickFormat={visitorTickFormat}
          label="Visitors"
          style={{
            axis: { fill: "none", stroke: "none" },
            ticks: { stroke: "#e3e3e3" },
            tickLabels: { fill: "#e3e3e3", fontSize: 8 }, // Numbers/ticks in grey
            axisLabel: { fill: "#96F0D9", fontSize: 10, padding: 35 }, //
          }}
        />

        {/* Right Y-Axis for Performance Metrics */}
        <VictoryAxis
          dependentAxis
          orientation="right"
          tickValues={yTickValues}
          tickFormat={metricsTickFormat}
          label="Metrics"
          style={{
            axis: { fill: "none", stroke: "none" },
            ticks: { stroke: "#e3e3e3" },
            tickLabels: { fill: "#e3e3e3", fontSize: 8 },
            axisLabel: { fill: "#FF4E4F", fontSize: 10, padding: 35 },
          }}
        />

        {/* Visitor Metrics Lines */}
        {normalizedVisitorMetrics.map((metric, index) => (
          <VictoryLine
            key={index}
            data={metric.data}
            style={{
              data: { stroke: metric.color },
            }}
          />
        ))}

        {/* Performance Metrics Lines */}
        {normalizedPerformanceMetrics.map((metric, index) => (
          <VictoryLine
            key={index}
            data={metric.data}
            style={{
              data: { stroke: metric.color },
            }}
          />
        ))}

        {/* Media Circle */}
        <VictoryScatter
          data={[
            {
              x: new Date(mediaData.timestamp),
              y: sharedYDomain[0],
              media_url: mediaData.media_url,
              media_type: mediaData.media_type,
            },
          ]}
          size={3}
          style={{
            data: { fill: "#e3e3e3" },
          }}
          labels={({ datum }) => datum}
          labelComponent={
            <VictoryTooltip
              flyoutComponent={<CustomMediaFlyout />}
              style={{ display: "none" }}
            />
          }
        />
      </VictoryChart>
    </div>
  );
};

export default ContentChart;
