import React from "react";
import {
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryLabel,
} from "victory";
import moment from "moment";
import "../style/App.css";

const MediaDisplay = ({ mediaType, mediaUrl }) => {
  return (
    <div
      onClick={() => {
        window.open(mediaUrl, "_blank");
      }}
      cursor="pointer"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%", // Container takes full width
        height: "auto", // Height adjusts automatically
      }}
    >
      {mediaType === "VIDEO" ? (
        <video
          src={mediaUrl}
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: "100%", // Fills the container width
            height: "auto", // Maintains aspect ratio
            maxWidth: "500px", // Optional: limit the max size
            borderRadius: "5px",
            objectFit: "contain",
            border: "1px solid #e3e3e3",
          }}
        />
      ) : (
        <img
          src={mediaUrl}
          alt="Media"
          style={{
            width: "100%", // Fills the container width
            height: "auto", // Maintains aspect ratio
            maxWidth: "500px", // Optional: limit the max size
            borderRadius: "5px",
            objectFit: "contain",
            border: "1px solid #e3e3e3",
          }}
        />
      )}
    </div>
  );
};

const testData = [
  { name: "Accuracy", value: 0.32 },
  { name: "Visitors", value: 100 },
  { name: "Value", value: 100 },
  { name: "Shop Interactions", value: 250 },
  { name: "Email Signups", value: 10 },
];

const CorrelationChart = ({ graphData, mediaData }) => {
  const { metrics, visitors } = graphData;

  // Helper function to preprocess data
  const preprocessData = (data) =>
    data.map((d) => ({ x: new Date(d.x), y: d.y }));

  // Split visitor data into separate metrics
  const visitorMetrics = [
    {
      name: "direct_visitors",
      data: preprocessData(
        visitors.map((v) => ({
          x: v.hour,
          y: v.direct_visitors || 0,
        }))
      ),
      color: "#A78BFA",
    },
    {
      name: "organic_google_visitors",
      data: preprocessData(
        visitors.map((v) => ({
          x: v.hour,
          y: v.organic_google_visitors || 0,
        }))
      ),
      color: "#FFD700",
    },
    {
      name: "organic_instagram_visitors",
      data: preprocessData(
        visitors.map((v) => ({
          x: v.hour,
          y: v.organic_instagram_visitors || 0,
        }))
      ),
      color: "#96F0D9",
    },
  ];

  // Preprocess metrics data
  const metricsData = metrics.map((metric) => ({
    name: metric.name,
    data: preprocessData(metric.values),
    range:
      Math.max(...metric.values.map((d) => d.y)) -
      Math.min(...metric.values.map((d) => d.y)),
    color: `#${Math.floor(Math.random() * 16777215).toString(16)}`, // Random color
  }));

  console.log("metricsData + colors", metricsData);

  // Dynamic metrics splitting logic based on data ranges
  const splitMetricsDynamically = (metrics) => {
    const sortedMetrics = metrics.sort((a, b) => b.range - a.range); // Sort by range (desc)

    const leftMetrics = [];
    const rightMetrics = [];
    let leftRangeTotal = 0;
    let rightRangeTotal = 0;

    sortedMetrics.forEach((metric) => {
      if (leftRangeTotal <= rightRangeTotal) {
        leftMetrics.push(metric);
        leftRangeTotal += metric.range;
      } else {
        rightMetrics.push(metric);
        rightRangeTotal += metric.range;
      }
    });

    return { leftMetrics, rightMetrics };
  };

  // Apply dynamic splitting
  const { leftMetrics, rightMetrics } = splitMetricsDynamically(metricsData);

  // Calculate ranges for visitors and metrics
  const calculateRange = (data) => [
    0, // Explicitly set the minimum to 0
    Math.max(...data.flatMap((m) => m.data.map((d) => d.y))),
  ];

  const directGoogleRange = calculateRange(visitorMetrics.slice(0, 2));
  const instagramRange = calculateRange(visitorMetrics.slice(2));
  const leftMetricsRange = calculateRange(leftMetrics);
  const rightMetricsRange = calculateRange(rightMetrics);

  // Normalization function
  const normalize = (value, range, domain = [0, 10]) => {
    const [min, max] = range;
    const [domainMin, domainMax] = domain;
    return ((value - min) / (max - min)) * (domainMax - domainMin) + domainMin;
  };

  // Tick formatting functions
  const createTickFormat = (range) => (t) =>
    ((t / 10) * (range[1] - range[0]) + range[0]).toFixed(1);

  // Normalize data
  const normalizedVisitors = visitorMetrics.map((metric, index) => ({
    ...metric,
    data: metric.data.map((d) => ({
      x: d.x,
      y: normalize(d.y, index === 2 ? instagramRange : directGoogleRange),
    })),
  }));

  const normalizedLeftMetrics = leftMetrics.map((metric) => ({
    ...metric,
    data: metric.data.map((d) => ({
      x: d.x,
      y: normalize(d.y, leftMetricsRange, [0, -10]), // Normalize to negative domain
    })),
  }));

  const normalizedRightMetrics = rightMetrics.map((metric) => ({
    ...metric,
    data: metric.data.map((d) => ({
      x: d.x,
      y: normalize(d.y, rightMetricsRange, [0, -10]), // Normalize to negative domain
    })),
  }));

  const getUniqueDays = (data) => {
    // Find the minimum and maximum dates in the data
    const minDate = moment.min(data.map((d) => moment(d.hour)));
    const maxDate = moment.max(data.map((d) => moment(d.hour)));

    // Generate an array of days from minDate to maxDate
    const days = [];
    let currentDate = minDate.clone();
    while (currentDate <= maxDate) {
      days.push(currentDate.toDate());
      currentDate.add(1, "day");
    }

    return days;
  };

  // Generate unique days for ticks
  const uniqueDays = getUniqueDays(visitors);

  return (
    <>
      <div className="correlation-grid">
        <MediaDisplay
          mediaType={mediaData.media_type}
          mediaUrl={mediaData.media_url}
        />
        <VictoryChart theme={VictoryTheme.material} width={600} height={300}>
          {/* X-Axis */}
          <VictoryAxis
            tickValues={uniqueDays} // Dynamically calculated unique days
            tickFormat={(t) => moment(t).format("D")}
            style={{
              tickLabels: {
                fontSize: 6,
                fill: "#e3e3e3",
              },
              axis: { stroke: "none" },
            }}
          />

          {/* Visitors: Left Y-Axis */}
          <VictoryAxis
            dependentAxis
            orientation="left"
            style={{
              tickLabels: { fontSize: 6, fill: "#96F0D9" },
              axis: { fill: "none", stroke: "none" },
            }}
            tickValues={[0, 2, 4, 6, 8, 10]}
            tickFormat={createTickFormat(directGoogleRange)}
          />
          {/* Label for Left Y-Axis */}
          <VictoryLabel
            text="Un-tracked visitors"
            x={20} // Adjust based on your chart's left margin
            y={30} // Top position
            style={{ fill: "#949E9C", fontSize: 8 }}
            textAnchor="start"
          />

          {/* Visitors: Right Y-Axis */}
          <VictoryAxis
            dependentAxis
            orientation="right"
            style={{
              tickLabels: { fontSize: 6, fill: "#96F0D9" },
              axis: { fill: "none", stroke: "none" },
            }}
            tickValues={[0, 2, 4, 6, 8, 10]}
            tickFormat={createTickFormat(instagramRange)}
          />
          {/* Label for Right Y-Axis */}
          <VictoryLabel
            text="Organic Instagram visitors"
            x={570} // Adjust based on chart width
            y={30} // Adjust for top position
            style={{ fill: "#949E9C", fontSize: 8 }}
            textAnchor="end"
          />

          {/* Metrics: Left Y-Axis */}
          <VictoryAxis
            dependentAxis
            orientation="left"
            offsetY={400}
            style={{
              tickLabels: { fontSize: 6, fill: "#96F0D9" },
              axis: { fill: "none", stroke: "none" },
            }}
            tickValues={[0, -2, -4, -6, -8, -10]}
            tickFormat={(t) => createTickFormat(leftMetricsRange)(-t)}
          />
          {/* Label for Metrics Left Y-Axis */}
          <VictoryLabel
            text="Reach metrics"
            x={20} // Adjust for left padding
            y={270} // Adjust for position
            style={{ fill: "#949E9C", fontSize: 8 }}
            textAnchor="start"
          />

          {/* Metrics: Right Y-Axis */}
          <VictoryAxis
            dependentAxis
            orientation="right"
            offsetY={400}
            style={{
              tickLabels: { fontSize: 6, fill: "#96F0D9" },
              axis: { fill: "none", stroke: "none" },
            }}
            tickValues={[0, -2, -4, -6, -8, -10]}
            tickFormat={(t) => createTickFormat(rightMetricsRange)(-t)}
          />
          {/* Label for Metrics Right Y-Axis */}
          <VictoryLabel
            text="Engagement metrics"
            x={570} // Adjust for right margin
            y={270} // Adjust for position
            style={{ fill: "#949E9C", fontSize: 8 }}
            textAnchor="end"
          />

          {/* Visitor Lines */}
          {normalizedVisitors.map((metric, index) => (
            <VictoryLine
              key={`visitor-${index}`}
              data={metric.data}
              style={{ data: { stroke: metric.color, strokeWidth: 2 } }}
            />
          ))}

          {/* Left Metrics Lines */}
          {normalizedLeftMetrics.map((metric, index) => (
            <VictoryLine
              key={`metric-left-${index}`}
              data={metric.data}
              style={{ data: { stroke: metric.color } }}
            />
          ))}

          {/* Right Metrics Lines */}
          {normalizedRightMetrics.map((metric, index) => (
            <VictoryLine
              key={`metric-right-${index}`}
              data={metric.data}
              style={{ data: { stroke: metric.color } }}
            />
          ))}
        </VictoryChart>
      </div>
      <div className="correlation-values">
        {testData.map((d) => {
          if (d.name === "Accuracy") {
            return (
              <div className="accuracy-circle" key={d.name}>
                <div className="circle">
                  <svg viewBox="0 0 36 36" className="circular-chart">
                    <path
                      className="circle-bg"
                      d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle-progress"
                      strokeDasharray={`${d.value * 100}, 100`}
                      d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                  </svg>
                  <div className="percentage">{Math.round(d.value * 100)}%</div>
                  <div className="percentage-label">{d.name.toLowerCase()}</div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="value-box" key={d.name}>
                <div className="value-label">{d.name}</div>
                <div className="value">{d.value}k</div>
              </div>
            );
          }
        })}
      </div>
    </>
  );
};

export default CorrelationChart;
