import React, { useState } from "react";
import "../style/App.css";
import api from "../modules/Api.js";
import ContentChart from "../modules/contentChart.js";

const ContentPerformance = () => {
  const [url, setUrl] = useState("");
  const [graphData, setGraphData] = useState(null);
  const [mediaData, setMediaData] = useState(null);
  const [logScale, setLogScale] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state

  const getContentPerformance = async () => {
    try {
      if (!url) {
        throw new Error("URL is required");
      }
      setLoading(true); // Set loading to true while fetching data
      const response = await api.post("/content-performance/url", { url });
      if (response.status !== 200) {
        throw new Error("Failed to fetch content performance");
      }
      console.log("response", response.data);
      setGraphData(response.data.graphData);
      setMediaData(response.data.media);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Ensure loading is turned off after fetch
    }
  };

  console.log("graphData", graphData);
  console.log("mediaData", mediaData);

  return (
    <div className="dashboard">
      <input
        type="text"
        placeholder="Enter URL"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />
      <button onClick={getContentPerformance}>Fetch</button>
      <label>
        <input
          type="checkbox"
          checked={logScale}
          onChange={(e) => setLogScale(e.target.checked)}
        />
        Log Scale
      </label>

      {/* Render loading state, error message, or the chart */}
      {loading && <p>Loading...</p>}
      {!loading && graphData && mediaData ? (
        <ContentChart
          graphData={graphData}
          mediaData={mediaData}
          logScale={logScale}
        />
      ) : (
        !loading && <p>Enter a URL and click fetch to see data.</p>
      )}
    </div>
  );
};

export default ContentPerformance;
