import React, { useState } from "react";
import "../style/App.css";
import api from "../modules/Api.js";
import CorrelationChart from "../modules/correlationChart.js";

const Correlation = () => {
  const [url, setUrl] = useState("");
  const [graphData, setGraphData] = useState(null);
  const [mediaData, setMediaData] = useState(null);
  const [loading, setLoading] = useState(false); // New loading state
  const [selectedThreshold, setSelectedThreshold] = useState(0.0);
  const [path, setPath] = useState("");

  const getContentPerformance = async () => {
    try {
      if (!url) {
        throw new Error("URL is required");
      }
      setLoading(true); // Set loading to true while fetching data
      const response = await api.post("/content-performance/url", {
        url,
        selectedThreshold,
        path,
      });
      if (response.status !== 200) {
        throw new Error("Failed to fetch content performance");
      }
      console.log("response", response.data);
      setGraphData(response.data.graphData);
      setMediaData(response.data.media);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Ensure loading is turned off after fetch
    }
  };

  console.log("graphData", graphData);
  console.log("mediaData", mediaData);

  const Spinner = () => (
    <div className="fetch-spinner">{/* Simple spinner using CSS */}</div>
  );

  const downloadCSV = () => {
    if (!graphData || !mediaData) {
      alert("No data available for download.");
      return;
    }

    // Collect all unique timestamps from visitors and metrics
    const allTimestamps = new Set();

    // Add timestamps from visitors
    graphData.visitors.forEach((visitor) => {
      allTimestamps.add(visitor.hour);
    });

    // Add timestamps from metrics
    graphData.metrics.forEach((metric) => {
      metric.values.forEach((value) => {
        allTimestamps.add(value.x);
      });
    });

    // Sort timestamps in descending order
    const sortedTimestamps = Array.from(allTimestamps).sort(
      (a, b) => new Date(b) - new Date(a)
    );

    // Extract media details
    const { media_id, permalink, media_type } = mediaData;

    // Initialize header with media details and timestamps in Column A, followed by metrics
    const headers = [
      "Media ID",
      "Permalink",
      "Media Type",
      "Timestamp",
      "Organic Instagram Visitors",
      "Direct Visitors",
      "Organic Google Visitors",
      ...graphData.metrics.map((metric) => metric.name), // Add metric names
    ];

    // Prepare data rows
    const rows = sortedTimestamps.map((timestamp) => {
      // Start the row with media_id and permalink
      const row = [media_id, permalink, media_type, timestamp];

      // Add visitors data for the timestamp
      const visitorData = graphData.visitors.find(
        (visitor) => visitor.hour === timestamp
      );
      row.push(
        visitorData?.organic_instagram_visitors || 0,
        visitorData?.direct_visitors || 0,
        visitorData?.organic_google_visitors || 0
      );

      // Add metrics data for the timestamp
      graphData.metrics.forEach((metric) => {
        const metricValue = metric.values.find(
          (value) => value.x === timestamp
        );
        row.push(metricValue ? metricValue.y : 0); // Add value or 0 if not found
      });

      return row;
    });

    // Combine headers and rows into a single CSV string
    const csvContent = [
      headers.join(","), // Add headers
      ...rows.map((row) => row.join(",")), // Add each row
    ].join("\n");

    // Create a downloadable file
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link and trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = `${media_id}_${media_type}_echo_data.csv`;
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
  };

  return (
    <div className="dashboard">
      <h2 className="echo-title">Echo (version 0.0.1)</h2>
      <p className="echo-subtitle">
        {
          "Run a deep AI analysis to get the business value of your organic content"
        }
      </p>
      <div className="fetch__data__form">
        <input
          style={{
            width: 350,
            height: 38,
            fontSize: 16,
            paddingLeft: 9,
            background: "#2f2f2f",
            border: "none",
            color: "white",
            borderRadius: 10,
          }}
          type="text"
          placeholder="Enter Instagram URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <input
          style={{
            width: 350,
            height: 38,
            fontSize: 16,
            paddingLeft: 9,
            background: "#2f2f2f",
            border: "none",
            color: "white",
            borderRadius: 10,
          }}
          type="text"
          placeholder="Enter Unique Page path"
          value={path}
          onChange={(e) => setPath(e.target.value)}
        />
        <select
          value={selectedThreshold}
          onChange={(e) => setSelectedThreshold(e.target.value)}
        >
          <option value="" disabled>
            Threshold
          </option>
          <option value={0.0}>0%</option>
          <option value={0.05}>5%</option>
          <option value={0.1}>10%</option>
          <option value={0.3}>30%</option>
          <option value={0.5}>50%</option>
        </select>
        <button onClick={getContentPerformance} className="button-with-spinner">
          {loading ? <Spinner /> : "FETCH ⚡️"}
        </button>
        {graphData && mediaData && (
          <button onClick={downloadCSV}>Download CSV</button>
        )}
      </div>

      {/* Render loading state, error message, or the chart */}
      {loading && <p>Loading...</p>}
      {!loading && graphData && mediaData ? (
        <CorrelationChart graphData={graphData} mediaData={mediaData} />
      ) : (
        ""
      )}
    </div>
  );
};

export default Correlation;
