import React from "react";
import {
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryScatter,
} from "victory";
import moment from "moment";
import "../style/App.css";

const CustomMediaFlyout = ({ x, y, datum }) => {
  const isVideo = datum.media_type === "VIDEO";

  const mediaWidth = 55; // Fixed width
  const aspectRatio = 16 / 9; // Adjust based on the media format
  const mediaHeight = mediaWidth * aspectRatio; // Dynamically calculate height
  const offset = 10; // Offset above the dot

  return (
    <g
      transform={`translate(${x - mediaWidth / 2}, ${
        y - mediaHeight - offset
      })`}
    >
      <foreignObject width={mediaWidth} height={mediaHeight}>
        {isVideo ? (
          <video
            src={datum.media_url}
            autoPlay
            loop
            muted
            playsInline
            style={{
              display: "block",
              width: "100%",
              height: "100%",
              objectFit: "contain", // Ensures the media is fully visible
              borderRadius: "5px",
            }}
          />
        ) : (
          <img
            src={datum.media_url}
            alt="Media"
            style={{
              display: "block",
              width: "100%",
              height: "100%",
              objectFit: "contain", // Ensures the media is fully visible
            }}
          />
        )}
      </foreignObject>
    </g>
  );
};

const DiscoveryChart = ({ metrics, media }) => {
  console.log("media", media);
  console.log("metrics", metrics);
  // Dynamically filter visitors based on selectedVisitorMetric
  const instagramVisitorsData = metrics.map((d) => ({
    x: new Date(d.x),
    y: d.visitors.organic_instagram || 0, // Use selectedVisitorMetric
  }));

  const googleVisitorsData = metrics.map((d) => ({
    x: new Date(d.x),
    y: d.visitors.google || 0,
  }));

  const directVisitorsData = metrics.map((d) => ({
    x: new Date(d.x),
    y: d.visitors.direct || 0,
  }));

  const reachData = metrics.map((d) => ({
    x: new Date(d.x),
    y: d.reach.organic || 0,
  }));

  const interactionsData = metrics.map((d) => ({
    x: new Date(d.x),
    y: d.interactions.organic || 0,
  }));

  const mediaScatterData = media.map((item) => ({
    x: new Date(item.x), // Media timestamp
    y: 0, // Fixed at y=0
    media_url: item.media_url, // Media URL for the tooltip
    media_type: item.media_type,
  }));

  // Ranges for visitors and reach
  const instagramVisitorsRange = [
    Math.min(...instagramVisitorsData.map((d) => d.y), 0),
    Math.max(...instagramVisitorsData.map((d) => d.y), 1),
  ];
  const googleVisitorsRange = [
    Math.min(...googleVisitorsData.map((d) => d.y), 0),
    Math.max(...googleVisitorsData.map((d) => d.y), 1),
  ];
  const directVisitorsRange = [
    Math.min(...directVisitorsData.map((d) => d.y), 0),
    Math.max(...directVisitorsData.map((d) => d.y), 1),
  ];
  const reachRange = [
    Math.min(...reachData.map((d) => d.y), 0),
    Math.max(...reachData.map((d) => d.y), 1),
  ];
  const interactionsRange = [
    Math.min(...interactionsData.map((d) => d.y), 0),
    Math.max(...interactionsData.map((d) => d.y), 1),
  ];

  // Shared Y domain for normalization
  const sharedYDomain = [0, 10];

  // Normalize function
  const normalize = (value, range) =>
    (value - range[0]) /
    ((range[1] - range[0]) / (sharedYDomain[1] - sharedYDomain[0]));

  // Normalize data
  const normalizedInstagramVisitorsData = instagramVisitorsData.map((d) => ({
    x: d.x,
    y: normalize(d.y, instagramVisitorsRange),
  }));
  const normalizedGoogleVisitorsData = googleVisitorsData.map((d) => ({
    x: d.x,
    y: normalize(d.y, googleVisitorsRange),
  }));
  const normalizedDirectVisitorsData = directVisitorsData.map((d) => ({
    x: d.x,
    y: normalize(d.y, directVisitorsRange),
  }));

  const normalizedReachData = reachData.map((d) => ({
    x: d.x,
    y: normalize(d.y, reachRange),
  }));

  const normalizedInteractionsData = interactionsData.map((d) => ({
    x: d.x,
    y: normalize(d.y, interactionsRange),
  }));

  // Tick format functions
  const instagramVisitorsTickFormat = (t) =>
    (t * (instagramVisitorsRange[1] - instagramVisitorsRange[0])) /
      sharedYDomain[1] +
    instagramVisitorsRange[0];

  const googleVisitorsTickFormat = (t) =>
    (t * (googleVisitorsRange[1] - googleVisitorsRange[0])) / sharedYDomain[1] +
    googleVisitorsRange[0];

  const directVisitorsTickFormat = (t) =>
    (t * (directVisitorsRange[1] - directVisitorsRange[0])) / sharedYDomain[1] +
    directVisitorsRange[0];

  const reachTickFormat = (t) =>
    (t * (reachRange[1] - reachRange[0])) / sharedYDomain[1] + reachRange[0];

  const interactionsTickFormat = (t) =>
    (t * (interactionsRange[1] - interactionsRange[0])) / sharedYDomain[1] +
    interactionsRange[0];

  // Calculate totals for media per media_type
  const mediaTypeCounts = media.reduce(
    (acc, item) => {
      acc[item.media_type] = (acc[item.media_type] || 0) + 1;
      return acc;
    },
    { IMAGE: 0, VIDEO: 0, CAROUSEL_ALBUM: 0 } // Default structure
  );

  // Calculate total metrics
  const totalMetrics = metrics.reduce(
    (acc, item) => {
      acc.totalReach += item.reach.total || 0;
      acc.organicReach += item.reach.organic || 0;
      acc.adsReach += item.reach.ads || 0;
      acc.organicInstagram += item.visitors.organic_instagram || 0;
      acc.googleVisitors += item.visitors.google || 0;
      acc.directVisitors += item.visitors.direct || 0;
      acc.totalInteractions += item.interactions.total || 0;
      acc.organicInteractions += item.interactions.organic || 0;
      acc.adsInteractions += item.interactions.ads || 0;
      return acc;
    },
    {
      totalReach: 0,
      organicReach: 0,
      adsReach: 0,
      organicInstagram: 0,
      googleVisitors: 0,
      directVisitors: 0,
      totalInteractions: 0,
      organicInteractions: 0,
      adsInteractions: 0,
    }
  );
  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(6, 1fr)",
          gap: "10px",
          marginBottom: "20px",
        }}
      >
        {/* Media Type Totals */}
        {Object.keys(mediaTypeCounts).map((key) => (
          <div
            key={key}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #e3e3e3",
              borderRadius: "5px",
              padding: "10px",
              backgroundColor: "#1c1c1c",
              color: "#e3e3e3",
            }}
          >
            <div style={{ fontSize: "12px", fontWeight: "bold" }}>{key}</div>
            <div style={{ fontSize: "16px" }}>{mediaTypeCounts[key]}</div>
          </div>
        ))}

        {/* Metrics Totals */}
        {Object.entries(totalMetrics).map(([key, value]) => (
          <div
            key={key}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #e3e3e3",
              borderRadius: "5px",
              padding: "10px",
              backgroundColor: "#1c1c1c",
              color: "#e3e3e3",
            }}
          >
            <div style={{ fontSize: "12px", fontWeight: "bold" }}>{key}</div>
            <div style={{ fontSize: "16px" }}>{value}</div>
          </div>
        ))}
      </div>

      <VictoryChart
        theme={VictoryTheme.material}
        domain={{ y: sharedYDomain }}
        scale={{ x: "time", y: "linear" }}
        width={700}
        height={350}
        style={{
          parent: {
            background: "#141414",
          },
        }}
        containerComponent={
          <VictoryVoronoiContainer
            labelComponent={
              <VictoryTooltip
                flyoutComponent={<CustomMediaFlyout />}
                flyoutStyle={{
                  fill: "transparent", // Ensure no background for the tooltip
                  stroke: "none",
                }}
                style={{
                  display: "none", // Hide the default text
                }}
              />
            }
          />
        }
      >
        {/* X-Axis */}
        <VictoryAxis
          tickValues={metrics.map((d) => new Date(d.x))} // Generate tickValues from the data
          tickFormat={(t) => moment(t).format("D")} // Format each tick as "Month Day"
          style={{
            tickLabels: { fontSize: 8, fill: "#e3e3e3" },
            axis: { fill: "none", stroke: "none" },
          }}
        />

        {/* Left Y-Axis for Reach */}
        <VictoryAxis
          dependentAxis
          orientation="left"
          label={"Reach"}
          tickValues={[0, 2, 4, 6, 8, 10]} // Match shared domain
          tickFormat={(t) => reachTickFormat(t).toFixed(0)} // Map to reach range
          style={{
            axis: { fill: "none", stroke: "none" },
            ticks: { stroke: "#e3e3e3" },
            tickLabels: { fill: "#e3e3e3", fontSize: 8 },
            axisLabel: { fill: "#FF4E4F", fontSize: 10, padding: 35 },
          }}
        />
        {/* Left Y-Axis for Visitors */}
        <VictoryAxis
          dependentAxis
          orientation="right"
          tickValues={[0, 2, 4, 6, 8, 10]} // Match shared domain
          tickFormat={(t) => interactionsTickFormat(t).toFixed(0)} // Map to visitors range
          label={"Interactions"}
          style={{
            axis: { fill: "none", stroke: "none" },
            ticks: { stroke: "#e3e3e3" }, // Ticks' color (lines)
            tickLabels: { fill: "#e3e3e3", fontSize: 8 }, // Numbers/ticks in grey
            axisLabel: { fill: "#2196F3", fontSize: 10, padding: 35 }, // Label in blue-ish
          }}
        />

        {/* Reach Line */}
        <VictoryLine
          data={normalizedReachData}
          style={{
            data: { stroke: "#FF4E4F" },
          }}
        />

        {/* Interactions Line */}
        <VictoryLine
          data={normalizedInteractionsData}
          style={{
            data: { stroke: "#2196F3" },
          }}
        />

        {/* Media Circles */}
        <VictoryScatter
          data={mediaScatterData}
          size={2} // Circle size
          style={{
            data: { fill: "#e3e3e3" }, // Circle color
          }}
          labels={({ datum }) => datum} // Pass the entire datum to the tooltip
          labelComponent={
            <VictoryTooltip
              flyoutComponent={<CustomMediaFlyout />}
              flyoutStyle={{
                fill: "transparent", // No tooltip background
                stroke: "none",
              }}
              style={{ display: "none" }} // Hide the default text tooltip        }
            />
          }
        />
      </VictoryChart>
      <VictoryChart
        theme={VictoryTheme.material}
        domain={{ y: sharedYDomain }}
        scale={{ x: "time", y: "linear" }}
        width={700}
        height={350}
        style={{
          parent: {
            background: "#141414",
          },
        }}
        containerComponent={
          <VictoryVoronoiContainer
            labelComponent={
              <VictoryTooltip
                flyoutComponent={<CustomMediaFlyout />}
                flyoutStyle={{
                  fill: "transparent", // Ensure no background for the tooltip
                  stroke: "none",
                }}
                style={{
                  display: "none", // Hide the default text
                }}
              />
            }
          />
        }
      >
        {/* X-Axis */}
        <VictoryAxis
          tickValues={metrics.map((d) => new Date(d.x))} // Generate tickValues from the data
          tickFormat={(t) => moment(t).format("D")} // Format each tick as "Month Day"
          style={{
            tickLabels: { fontSize: 8, fill: "#e3e3e3" },
            axis: { fill: "none", stroke: "none" },
          }}
        />

        {/* Left Y-Axis for Reach */}
        <VictoryAxis
          dependentAxis
          orientation="left"
          label={"Un-tracked Visitors"}
          tickValues={[0, 2, 4, 6, 8, 10]} // Match shared domain
          tickFormat={(t) => directVisitorsTickFormat(t).toFixed(0)} // Map to reach range
          style={{
            axis: { fill: "none", stroke: "none" },
            ticks: { stroke: "#e3e3e3" },
            tickLabels: { fill: "#e3e3e3", fontSize: 8 },
            axisLabel: { fill: "#FE00C6", fontSize: 10, padding: 35 },
          }}
        />
        {/* Left Y-Axis for Visitors */}
        <VictoryAxis
          dependentAxis
          orientation="right"
          tickValues={[0, 2, 4, 6, 8, 10]} // Match shared domain
          tickFormat={(t) => instagramVisitorsTickFormat(t).toFixed(0)} // Map to visitors range
          label={"Organic Instagram Visitors"}
          style={{
            axis: { fill: "none", stroke: "none" },
            ticks: { stroke: "#e3e3e3" }, // Ticks' color (lines)
            tickLabels: { fill: "#e3e3e3", fontSize: 8 }, // Numbers/ticks in grey
            axisLabel: { fill: "#96F0D9", fontSize: 10, padding: 35 }, // Label in blue-ish
          }}
        />

        {/* Reach Line */}
        <VictoryLine
          data={normalizedDirectVisitorsData}
          style={{
            data: { stroke: "#FE00C6" },
          }}
        />

        {/* Google Visitors Line */}
        <VictoryLine
          data={normalizedGoogleVisitorsData}
          style={{
            data: { stroke: "#9473D6" },
          }}
        />

        {/* Interactions Line */}
        <VictoryLine
          data={normalizedInstagramVisitorsData}
          style={{
            data: { stroke: "#96F0D9" },
          }}
        />

        {/* Media Circles */}
        <VictoryScatter
          data={mediaScatterData}
          size={2} // Circle size
          style={{
            data: { fill: "#e3e3e3" }, // Circle color
          }}
          labels={({ datum }) => datum} // Pass the entire datum to the tooltip
          labelComponent={
            <VictoryTooltip
              flyoutComponent={<CustomMediaFlyout />}
              flyoutStyle={{
                fill: "transparent", // No tooltip background
                stroke: "none",
              }}
              style={{ display: "none" }} // Hide the default text tooltip        }
            />
          }
        />
      </VictoryChart>
    </>
  );
};

export default DiscoveryChart;
