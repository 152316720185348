import React, { useState } from "react";
import api from "../modules/Api";

export default function Comments() {
  const [comments, setComments] = useState([]);
  const [accountId, setAccountId] = useState("");
  const [commentsLoading, setCommentsLoading] = useState(false);

  const fetchComments = async () => {
    try {
      setCommentsLoading(true);
      setComments([]);
      const response = await api.post("/comments/list", {
        accountId,
      });
      if (response.status !== 200) {
        console.error("Error fetching comments:", response.data.message);
      }
      console.log(response.data);
      setComments(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setCommentsLoading(false);
    }
  };

  const Spinner = () => (
    <div className="fetch-spinner">{/* Simple spinner using CSS */}</div>
  );

  return (
    <div className="dashboard">
      <div className="fetch__data__form">
        <select
          value={accountId}
          onChange={(e) => setAccountId(e.target.value)}
        >
          <option value="" disabled>
            Account
          </option>
          <option value="17841403504283995">Dometic</option>
          <option value="17841400009052327">Stellar Equipment</option>
          <option value="17841401210143768">POC</option>
          <option value="17841400802988062">J.Lindeberg</option>
        </select>
        <button onClick={fetchComments} className="button-with-spinner">
          {commentsLoading ? <Spinner /> : "Fetch ⚡️"}
        </button>
      </div>
      <div className="comments-list">
        {comments &&
          comments.map((comment) => (
            <div key={comment._id} className="comment-item">
              <span>{new Date(comment.timestamp).toLocaleString()}</span>
              <p>{comment.text}</p>
              {comment.replies &&
                comment.replies.map((reply) => (
                  <p key={reply._id}>Reply: {reply.text}</p>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
}
