import React, { useState } from "react";
import api from "../modules/Api.js";
import "../style/App.css";

const CustomerJourneys = () => {
  const [accountUrl, setAccountUrl] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [type, setType] = useState("");
  const [platform, setPlatform] = useState("");
  const [customerJourney, setCustomerJourney] = useState(null);
  const [collapsedStates, setCollapsedStates] = useState({});
  const [customerJourneyLoading, setCustomerJourneyLoading] = useState(false);
  const [otherEvents, setOtherEvents] = useState({});
  const [event, setEvent] = useState("");

  const fetchCustomerJourney = async () => {
    try {
      setCustomerJourneyLoading(true);
      setCustomerJourney(null);
      setOtherEvents({});

      const response = await api.post("/customer/journeys", {
        accountUrl,
        type,
        platform,
        startDate,
        endDate,
        event,
      });

      if (response.status !== 200) {
        console.error(
          "Error fetching customer journey:",
          response.data.message
        );
        return;
      }

      setCustomerJourney(response.data.customerJourneys);
      setOtherEvents(response.data.otherEvents);
    } catch (error) {
      console.error("Error fetching customer journey:", error.message);
    } finally {
      setCustomerJourneyLoading(false);
    }
  };

  const calculateTimeDifference = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const diffMs = end - start;

    const days = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

    const parts = [];
    if (days > 0) parts.push(`${days} days`);
    if (hours > 0) parts.push(`${hours} hours`);
    if (minutes > 0 || parts.length === 0) parts.push(`${minutes} minutes`);

    return parts.join(", ");
  };

  const toggleCollapse = (id) => {
    setCollapsedStates((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const Spinner = () => <div className="fetch-spinner"></div>;

  return (
    <div className="dashboard">
      <div className="fetch__data__form">
        <select
          value={accountUrl}
          onChange={(e) => setAccountUrl(e.target.value)}
        >
          <option value="" disabled>
            Account
          </option>
          <option value="https://dometic.com/">Dometic</option>
          <option value="https://stellarequipment.com">
            Stellar Equipment
          </option>
          <option value="https://www.poc.com">POC</option>
          <option value="https://www.jlindeberg.com">J.Lindeberg</option>
          <option value="https://bmk.health">BKM Health</option>
        </select>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <select value={type} onChange={(e) => setType(e.target.value)}>
          <option value="" disabled>
            Type
          </option>
          <option value="paid">Paid</option>
          <option value="organic">Organic</option>
        </select>
        <select value={platform} onChange={(e) => setPlatform(e.target.value)}>
          <option value="" disabled>
            Platform
          </option>
          <option value="tiktok">TikTok</option>
          <option value="instagram">Instagram</option>
          <option value="direct">Direct</option>
          <option value="google">Google</option>
        </select>
        <select value={event} onChange={(e) => setEvent(e.target.value)}>
          <option value="" disabled>
            Event
          </option>
          <option value="purchase">Purchase</option>
          <option value="subscription">Subscription</option>
          <option value="cart">Cart</option>
          <option value="begin">Begin</option>
        </select>
        <button onClick={fetchCustomerJourney} className="button-with-spinner">
          {customerJourneyLoading ? <Spinner /> : "Fetch ⚡️"}
        </button>
      </div>

      <div className="customer-journey-result">
        {customerJourney && (
          <div className="customer-journey-result-header">
            <h4>
              <strong>{event}s:</strong> {Object.keys(customerJourney).length}
            </h4>
            {Object.entries(otherEvents).map(
              ([key, value]) =>
                value > 0 && (
                  <h4 key={key}>
                    <strong>
                      {key.charAt(0).toUpperCase() + key.slice(1)}s:
                    </strong>{" "}
                    {value}
                  </h4>
                )
            )}
          </div>
        )}

        {customerJourney &&
          Object.entries(customerJourney)
            .sort(([idA, actionsA], [idB, actionsB]) => {
              const checkoutA = actionsA.find(
                (action) => action.action === event
              );
              const checkoutB = actionsB.find(
                (action) => action.action === event
              );

              const timestampA = checkoutA
                ? new Date(checkoutA.timestamp)
                : new Date();
              const timestampB = checkoutB
                ? new Date(checkoutB.timestamp)
                : new Date();

              // Oldest timestamps come first
              return timestampA - timestampB;
            })
            .map(([id, actions]) => {
              const firstVisit = actions.find(
                (action) => action.action === "visit"
              );
              const mainEvent = actions.find(
                (action) => action.action === event
              );

              const timeToMainEvent =
                firstVisit && mainEvent
                  ? calculateTimeDifference(
                      firstVisit.timestamp,
                      mainEvent.timestamp
                    )
                  : "N/A";

              return (
                <div key={id} className="customer-box">
                  <div
                    className="customer-header"
                    onClick={() => toggleCollapse(id)}
                  >
                    <span className="customer-id">
                      {mainEvent?.timestamp
                        ? new Date(mainEvent.timestamp)
                            .toISOString()
                            .split("T")[0]
                        : "N/A"}
                    </span>
                    <span className="customer-id">Customer: {id}</span>
                    <span className="time-to-checkout">
                      Time to Event: {timeToMainEvent}
                    </span>
                    <span className="toggle-arrow">
                      {collapsedStates[id] ? "▲" : "▼"}
                    </span>
                  </div>
                  {collapsedStates[id] && (
                    <ul className="customer-actions">
                      {actions.map((action, index) => (
                        <li key={index}>
                          <strong>{action.action}</strong> - {action.timestamp}
                          <br />
                          <em>Referrer:</em> {action.referrer}
                          <br />
                          <em>URL:</em> {action.url}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default CustomerJourneys;
