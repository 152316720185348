import "../style/App.css";
import { useCookie } from "../modules/CookieContext.js";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "../pages/dashboard.js";
import NavBar from "../modules/NavBar.js";
import RequireAuth from "../modules/RequireAuth.js";
import Competitors from "../pages/competitors.js";
import Privileges from "../pages/privileges.js";
import RequestAccess from "../pages/requestAccess.js";
import Customize from "../pages/customize.js";
import Invite from "../pages/invite.js";
import Integrations from "../pages/Integrations.js";
import Privacy from "../pages/privacy.js";
import Terms from "../pages/terms.js";
import Funnels from "../pages/funnels.js";
import Seed from "../pages/seed.js";
import Reports from "../pages/reports.js";
import TestDashboard from "../pages/testDashboard.js";
import Luzmo from "../pages/luzmo.js";
import Home from "../pages/home.js";
import CustomerJourneys from "../pages/customerJourneys.js";
import Comments from "../pages/comments.js";
import Discovery from "../pages/discovery.js";
import ContentPerformance from "../pages/contentPerformance.js";
import Correlation from "../pages/correlation.js";
function Routing() {
  const { role, name } = useCookie();

  const MainLayout = ({ children }) => (
    <div style={{ display: "flex" }}>
      <NavBar role={role} />
      <div style={{ flexGrow: 1, marginLeft: "80px" }}>{children}</div>
    </div>
  );

  return (
    <Router>
      <Routes>
        <Route
          path="/invite"
          element={
            <RequireAuth>
              <MainLayout>
                <Invite />
              </MainLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/reports"
          element={
            <RequireAuth>
              <MainLayout>
                <Reports />
              </MainLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/seed"
          element={
            <RequireAuth>
              <MainLayout>
                <Seed />
              </MainLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/integrations"
          element={
            <RequireAuth>
              <MainLayout>
                <Integrations />
              </MainLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/privileges"
          element={
            <RequireAuth>
              <MainLayout>
                <Privileges />
              </MainLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/customize"
          element={
            <RequireAuth>
              <MainLayout>
                <Customize />
              </MainLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/funnels"
          element={
            <RequireAuth>
              <MainLayout>
                <Funnels />
              </MainLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/content-performance"
          element={
            <RequireAuth>
              <MainLayout>
                <ContentPerformance />
              </MainLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/dash"
          element={
            <RequireAuth>
              <MainLayout>
                <Luzmo />
              </MainLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/home"
          element={
            <RequireAuth>
              <MainLayout>
                <Home name={name} />
              </MainLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/discovery"
          element={
            <RequireAuth>
              <MainLayout>
                <Discovery />
              </MainLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/correlation"
          element={
            <RequireAuth>
              <MainLayout>
                <Correlation />
              </MainLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/customer-journeys"
          element={
            <RequireAuth>
              <MainLayout>
                <CustomerJourneys />
              </MainLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/comments"
          element={
            <RequireAuth>
              <MainLayout>
                <Comments />
              </MainLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/test-dash"
          element={
            <RequireAuth>
              <MainLayout>
                <TestDashboard />
              </MainLayout>
            </RequireAuth>
          }
        />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="request-access" element={<RequestAccess />} />
        <Route path="/" element={<Competitors />} />
        {/* <Route path="*" element={<Competitors />} /> */}
      </Routes>
    </Router>
  );
}

export default Routing;
